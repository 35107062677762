html, body {
  height: 100%;
}

.App {
 height: 100%;
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  height: 100%;
}

.grid {
  background-color: red;
  display: flex;
    flex-direction: column;
    height: 100%;
}

.row1 {
  background-color: blue;
}

.row2 {
  background-color: green;
    flex:2;
    display: flex;
}